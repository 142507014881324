import React, { useEffect, useState } from 'react';
import TopNavBar from '../components/TopNavbar';
import Footer from '../components/Footer';
import { get } from '../api_calls/galleryApi';

const GalleryPage = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        const response = await get();
        if (response.images) {
            const slicedImages = response.images.slice(0, 5);
            setImages(slicedImages);
        }
    };

    return (
        <div className="main">
            <TopNavBar />
            <section className='home-section'>
                <div className='home'>
                    <div className='container title_section'>
                        <h4>Gallery</h4>
                    </div>
                    <div className='container page_content'>
                        <div className="imageContainer" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))',}}>
                            {images.map((image, index) => (
                                <div key={index} className="imageWrapper_web">
                                    <div className='image_overlay'></div>
                                    <div className="imageWithDeleteIcon" style={{ backgroundImage: `url(data:image/png;base64,${image.image})`, }}></div>
                                </div>
                            ))}
                            {images.length >= 5 && (
                                <div className="">
                                    <button className="loadMoreButton">View More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <div className='home'></div>
            <Footer />
        </div>
    );
};

export default GalleryPage;
