import { message } from 'antd';
import React, { useState } from 'react'
import { forgotPassword, verify } from '../../api_calls/webUsersApi';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: '',
    otp: '',
  });
  const [otpSent, setOtpSent] = useState(false);
  const [buttonText, setButtonText] = useState('Get OTP');

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      var response = await verify(formData);
      if(response && response.success) {
        message.success(response.message);
        navigate(`/newPassword/${formData.userName}`);
      } else{
        message.error(response ? response.message : 'Unknown error');
      }
    } catch (error) {
      console.error('Error during login:', error);
      message.error('Error during login. Please try again later.');
    }
  };

  const handleGetOtp = async (e) => {
    try {
      if(formData.userName === ''){
        message.error('Please fill the user name')
      } else{
        var response = await forgotPassword(formData.userName);
        console.log(response);
        if(response.success){
          setOtpSent(true);
          setButtonText('Verify OTP');
          message.success(response.message);
        } else {
          message.error(response.message)
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      message.error('Error during login. Please try again later.');
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name] : e.target.value
    });
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (buttonText === 'Get OTP') {
      handleGetOtp();
    } else {
      handleSubmit();
    }
  };

  return (
    <div className="loginComponent">
      <div className='login'>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className='inputsGroup'>
            <div className='form-group'>
              <input
                type='text'
                id='userName'
                name='userName'
                value={formData.userName}
                onChange={handleChange}
                required
                placeholder='User Name*'
              />
            </div>
            {otpSent && 
              <div>
                <div className='form-group' style={{marginBottom:"0.5rem"}}>
                  <input
                    type='text'
                    id='otp'
                    name='otp'
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    placeholder='OTP*'
                  />
                </div>
                <p onClick={handleGetOtp} style={{marginBottom: '1rem', cursor: 'pointer'}}>Resend OTP</p>
              </div>
            }
          </div>
          <button type='submit' onClick={handleButtonClick}>{buttonText}</button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
