import React from 'react';
import logo from '../assets/srbslogo2.png'
import '../homestyle.css'

const TopNavBar = () => {
    const showNav = () => {
        document.getElementById('small_screen_menu').classList.remove('hide');
        document.getElementById('nav_close').classList.remove('hide');
    }
    const hideNav = () => {
        document.getElementById('small_screen_menu').classList.add('hide');
        document.getElementById('nav_close').classList.remove('hide');
    }
    return (

        <section className='nav_bar'>
            <div className='nav_header'>
                <div className='brand'>
                    <img className="brand_logo" src={logo} alt='brand'></img>
                </div>
                <ul className='nav_menu'>
                    <li className='nav_menu_item'><a className="nav_link" href='/home'>Home</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/about'>About</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/people'>People</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/resources'>Resources</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/srbsevents'>Events</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/srbsgallery'>Gallery</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='#footer'>Contact</a></li>

                </ul>
                {!sessionStorage.getItem('userData') && <li className='nav_menu_item login_link'><a className="nav_link" href='/login'>Login</a></li>}
            </div>
            <div className='nav_overlay'>
                <div className='brand'>
                    <img className="brand_logo" src={logo} alt='brand'></img>
                </div>

                <li id="nav_bars" className='nav_menu_item'><button className="nav_link" onClick={showNav} href="#"><i className='fa fa-bars'></i></button></li>
                <ul id="small_screen_menu" className='nav_menu hide'>
                    <li id="nav_close" className='nav_menu_item hide'><button className="nav_link" onClick={hideNav}><i className='fa fa-times'></i></button></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/home'>Home</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/about'>About</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/people'>People</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/resources'>Resources</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/srbsevents'>Events</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='/srbsgallery'>Gallery</a></li>
                    <li className='nav_menu_item'><a className="nav_link" href='#footer'>Contact</a></li>

                </ul>

            </div>
        </section>


    );
};

export default TopNavBar;