import React, { useEffect, useState } from 'react'
import {getAll} from '../api_calls/eventTypeApi'
import AddEventTypeForm from '../components/eventTypes/addEventTypeForm';
import EditEventType from '../components/eventTypes/editEventTypeForm';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Modal } from 'antd';

const EventType = () => {
  const [eventTypes, setEventTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState(null);

  useEffect(() => {
    fetchEventTypes();
  }, []);

  const fetchEventTypes = async () => {
    var response = await getAll();
    setEventTypes(response.data);
  };

  const handleEditEventType = (eventType) => {
    setSelectedEventType(eventType);
    setShowEditModal(true);
  };

  const handleAddEventType = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
    setShowEditModal(false);
};

  return (
    <div className='eventType'>
      <div>
        <div className='web-header'>
          <h1>Event Types</h1>
          <button onClick={handleAddEventType} className='AddUser'><ControlPointIcon/> <p>Add Event Type</p></button>
        </div>
        <hr />
        <Modal
          title="Add event type"
          visible={showModal}
          onCancel={handleModalClose}
          footer={null}
        >
          <AddEventTypeForm setShowModal= {setShowModal} fetchEventTypes={fetchEventTypes}/>
        </Modal>
        <Modal
          title="Edit event type"
          visible={showEditModal}
          onCancel={handleModalClose}
          footer={null}
        >
          <EditEventType eventType={selectedEventType} setShowEditModal={setShowEditModal} fetchEventTypes={fetchEventTypes} />
        </Modal>
        <table className='webUser-table'>
          <thead>
              <tr>
                  <th>Title</th>
                  <th>Duration</th>
                  <th>Desciption</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
              {eventTypes.map(eventTypes => (
                  <tr key={eventTypes._id}>
                      <td>{eventTypes.title}</td>
                      <td>{eventTypes.duration}</td>
                      <td>{eventTypes.description}</td>
                      <td className='actionBtnColumn'>
                          <button className='web-actionButton' style={{marginLeft: "5rem"}} onClick={() => handleEditEventType(eventTypes)}><DriveFileRenameOutlineIcon/></button>
                      </td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EventType