import React, { useEffect, useState } from 'react';
import { getAll } from '../api_calls/eventApi';
// import { useNavigate } from 'react-router-dom';
// import { loginform } from '../api_calls/webUsersApi';
// import { message } from 'antd';
import TopNavBar from '../components/TopNavbar'
import Footer from '../components/Footer'
import banner from '../assets/logo2.png'
import '../homestyle.css'
// import ViewInvitation from '../components/events/viewInvitation';


const HomePage = () => {
    const [events, setEvents] = useState([]);
    // const [showEventPaper, setShowEventPaper] = useState(false);
    // const [selectedEventPaper, setSelectedEventPaper] = useState('');
    // const [showInvitation, setShowInvitation] = useState(false);
    // const [selectedInvitation, setSelectedInvitation] = useState('');

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        var response = await getAll();
        setEvents(response.data.reverse());
    };

    // const handleViewEventPaper = (paper) => {
    //     setSelectedEventPaper(paper);
    //     setShowEventPaper(true);
    // }

    // const handleViewInvitation = async (invitation) => {
    //     setSelectedInvitation(invitation);
    //     setShowInvitation(true);
    // }

    return (
        <div className="main">
            <TopNavBar />
            <section className='home-section'>
                <div className='home'>
                    <div className='banner container'>
                        <div className='overlay'></div>
                        <img className='banner-image' src={banner} alt="banner" />
                        <div className='my-4 text-center'>
                            <a className='app-store-btn' href="/playstore-link">Availble on <br /><i className='fa-brands fa-google-play'> </i><span>Goole Play</span></a>
                            <a className='app-store-btn' href="/appstore-link">Availble on <br /> <i className='fa-brands fa-apple'> </i> <span>AppStore</span></a>
                        </div>
                    </div>
                </div>
            </section>
            <div className='home'>
                {events.length > 0 && <div className='floating_event'>
                    <div className='floating_invitation'><img src={'data:image/png;base64,' + events[0].invitation} alt="Red dot" /></div>
                    <p className='up'>Upcoming Event</p>
                    <p className='floating_event_title'>{events[0].eventType.title}</p>
                    <p className='date'>{events[0].startDate.split('T')[0]}</p>
                    <p className='timer'>0 days to go</p>
                </div>
                }
            </div>

            <Footer />
        </div>
    );
};

export default HomePage;