import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginform } from '../api_calls/webUsersApi';
// import { message } from 'antd';
import TopNavBar from '../components/TopNavbar'
import Footer from '../components/Footer'
// import banner from '../assets/Banners.jpg'
import '../homestyle.css'

const Resources = () => {

    return (
        <div className="main">
            <TopNavBar />
            <section className='home-section'>
                <div className='home'>
                    <div className='container title_section'>
                        <h4>Resources</h4>
                    </div>
                    <div className='container page_content'>
                        <div className='content'>
                            <h3 className='text-center'>Will be updated Soon...</h3>
                            <p></p>
                        </div>
                    </div>
                </div>
            </section>
            <div className='home'>

            </div>
            <Footer />
        </div>
    );
};

export default Resources;