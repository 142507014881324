import React, { useEffect, useState } from 'react';
import { deleteById, getAll } from '../api_calls/eventApi';
import AddEventForm from '../components/events/addEventForm';
import EditEventForm from '../components/events/editEventForm';
import { Modal, message, Tooltip } from 'antd';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PdfUploader from '../components/events/appProgramPaperForm';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InvitationUploader from '../components/events/invitationUploader';
import ViewInvitation from '../components/events/viewInvitation';

const Events = () => {
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [showPdfUploader, setShowPdfUploader] = useState(false);
    const [imageUploader, setImageUploader] = useState(false);
    const [showEventPaper, setShowEventPaper] = useState(false);
    const [selectedEventPaper, setSelectedEventPaper] = useState('');
    const [showInvitation, setShowInvitation] = useState(false);
    const [selectedInvitation, setSelectedInvitation] = useState('');

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        var response = await getAll();
        setEvents(response.data.reverse());
    };

    const handleEditEvent = (event) => {
        setSelectedEvent(event);
        setShowEditModal(true);
    };
    
    const handleDeleteEvent = async (id) => {
        var response = await deleteById(id);
        message.success(response.message);
        await fetchEvents();
    };

    const handleAddEvent = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setShowEditModal(false);
        setShowEventPaper(false);
        setShowInvitation(false);
    };
    
    const handleEventPaper = async (event) => {
        await setSelectedEvent(event);
        await setShowPdfUploader(true);
    };

    const handleInvitation = async (event) => {
        await setSelectedEvent(event);
        await setImageUploader(true);
    }

    const handleClosePdfUploader = () => {
        setShowPdfUploader(false); 
    };

    const handleClosedInvitationUploader = () => {
        setImageUploader(false);
        window.location.reload();
    }

    const handleViewEventPaper =(paper) => {
        setSelectedEventPaper(paper);
        setShowEventPaper(true);
    }

    const handleViewInvitation = async (invitation) => {
        setSelectedInvitation(invitation);
        setShowInvitation(true);
        
    }
    
    return (
        <div className='events'>
            <div style={{ width: "100%" }}>
                <div className='web-header'>
                    <h1>Events List</h1>
                    <button onClick={handleAddEvent} className='AddUser'>
                        <ControlPointIcon /> <p>Add Event</p>
                    </button>
                </div>
                <hr />
                <Modal
                    title="Add event"
                    visible={showModal}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <AddEventForm setShowModal={setShowModal} fetchEvents={fetchEvents} />
                </Modal>
                <Modal
                    title="Edit event details"
                    visible={showEditModal}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <EditEventForm event={selectedEvent} setShowEditModel={setShowEditModal} fetchEvents={fetchEvents} />
                </Modal>
                <Modal
                    title="Program paper"
                    visible={showEventPaper}
                    onCancel={handleModalClose}
                    footer={null}
                    centered
                    className='programPaperModal'
                >
                    <embed src={`data:application/pdf;base64,${selectedEventPaper}`} type="application/pdf" width="100%" height="700px" />
                </Modal>
                <Modal
                    title="Invitation"
                    visible={showInvitation}
                    onCancel={handleModalClose}
                    footer={null}
                    centered
                    className='programPaperModal'
                >
                    <ViewInvitation invitation={selectedInvitation}/>
                </Modal>
                <table className='events-table'>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Event Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Venue</th>
                            <th>Time</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map(event => (
                            <tr key={event.id}>
                                <td>{event.userId.userName}</td>
                                <td>{event.eventType.title}</td>
                                <td>{event.startDate}</td>
                                <td>{event.endDate}</td>
                                <td>{event.venue}</td>
                                <td>{event.time}</td>
                                <td className='actionBtnColumn'>
                                    <Tooltip title={event.invitation?"View initation":"Add invitation"}>
                                        <button className='web-actionButton' onClick={() => {event.invitation?handleViewInvitation(event.invitation):handleInvitation(event.id)}}>{event.invitation?<RemoveRedEyeIcon/>:<CardGiftcardIcon />}</button>
                                    </Tooltip>
                                    <Tooltip title= {event.program_paper?"View program paper": "Add program paper"}>
                                        <button className='web-actionButton' onClick={() => {event.program_paper?handleViewEventPaper(event.program_paper):handleEventPaper(event.id)}}>{event.program_paper?<RemoveRedEyeIcon/>:<ReceiptLongIcon />}</button>
                                    </Tooltip>
                                    <Tooltip title="Edit event">
                                        <button className='web-actionButton' onClick={() => handleEditEvent(event)}><DriveFileRenameOutlineIcon /></button>
                                    </Tooltip>
                                    <Tooltip title="Delete event">
                                        <button className='web-actionButton' onClick={() => handleDeleteEvent(event.id)}><DeleteIcon /></button>
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PdfUploader visible={showPdfUploader} onClose={handleClosePdfUploader} id={selectedEvent} fetchEvents={fetchEvents} />
            <InvitationUploader visible={imageUploader} onClose={handleClosedInvitationUploader} id={selectedEvent} fetchEvents={fetchEvents}/>
        </div>
    );
};

export default Events;
