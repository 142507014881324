import React, { useState, useEffect } from 'react';
import { update } from '../../api_calls/eventTypeApi';

const EditEventType = ({ eventType, setShowEditModal, fetchEventTypes }) => {
    const [formData, setFormData] = useState({
        title: '',
        duration: '',
        description: '',
    });

    useEffect(() => {
        if (eventType) {
            setFormData({
                title: eventType.title || '',
                duration: eventType.duration || '',
                description: eventType.description || '',
            });
        }
    }, [eventType]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await update(eventType._id, formData);
            console.log(response);
            setShowEditModal(false); 
            await fetchEventTypes();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };
    
    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Duration:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name="title" value={formData.title} onChange={handleChange} />
                        <input type="text" name="duration" value={formData.duration} onChange={handleChange} />
                        <input type="text" name="description" value={formData.description} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Update event type</button>
                </div>
            </form>
        </div>
    );
}

export default EditEventType;
