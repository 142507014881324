import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updatePassword } from '../../api_calls/webUsersApi';
import { message } from 'antd';

const NewPassword = () => {
    const {userName} = useParams();
        const navigate = useNavigate();
        const [formData, setFormData] = useState({
            userName: userName,
            newPassword: ''
        });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var response = await updatePassword(formData);
            if(response && response.success) {
                message.success(response.message);
                navigate('/login');
            } else {
                message.error(response ? response.message : 'Unknown error');
            }
        } catch (error) {
            console.error('Error during login:', error);
            message.error('Error during login. Please try again later.');
        }
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

        return (
            <div className="loginComponent">
                <div className='login'>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className='inputsGroup'>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    id='newpassword'
                                    name='newPassword'
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    required
                                    placeholder='Enter new password*'
                                />
                            </div>
                        </div>
                        <button type='submit'>Update Password</button>
                    </form>
                </div>
            </div>
        )
}

export default NewPassword