import React, { useState } from 'react'
import { add } from '../../api_calls/donationApi';

const AddDonationTypeForm = (props) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        amount: '',
    });

const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });
};

const handleSubmit = async (e) => {
    e.preventDefault();
    var response = await add(formData);
    console.log(response);
    props.setShowModal(false);
    await props.fetchDonationTypes();
}

    return (
        <div className='modelForm'>
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Amount:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name="title" value={formData.title} onChange={handleChange} />
                        <input type="text" name="amount" value={formData.amount} onChange={handleChange} />
                        <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type="submit">Add Event Type</button>
                </div>
            </form>
        </div>
    )
}

export default AddDonationTypeForm