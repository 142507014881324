// import React, { useEffect, useState } from 'react';
// import { getAll, deleteUser } from '../api_calls/mobileUsersApi';
// import EditMobileUser from '../components/mobileUsers/editMobileUserForm';
// import { Modal, message } from 'antd';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
// import DeleteIcon from '@mui/icons-material/Delete';

// const MobileUsers = () => {
//     const [users, setUsers] = useState([]);
//     const [showEditModal, setShowEditModal] = useState(false);
//     const [selectedUser, setSelectedUser] = useState(null);

//     useEffect(() => {
//         fetchUsers();
//     }, []);

//     const fetchUsers = async () => {
//         var response = await getAll();
//         setUsers(response.data.reverse());
//     };

//     const handleEditUser = (user) => {
//         setSelectedUser(user);
//         setShowEditModal(true);
//     };

//     const handleDeleteUser = async (userId) => {
//         var response = await deleteUser(userId);
//         message.success(response.message);
//         await fetchUsers();
//     };

//     const handleRefresh = async () => {
//         await fetchUsers();
//         message.success("Users refreshed successfully");
//     };

//     const handleModalClose = () => {
//         setShowEditModal(false);
//     };

//     return (
//         <div className='mobileUsers'>
//             <div>
//                 <div className='web-header'>
//                     <h1>Mobile Users</h1>
//                     <button onClick={handleRefresh} className='AddUser'>
//                         <RefreshIcon /> <p>Refresh</p>
//                     </button>
//                 </div>
//                 <hr />
//                 <Modal
//                     title= "Edit mobile user"
//                     visible= {showEditModal}
//                     onCancel={handleModalClose}
//                     footer={null}
//                 >
//                     {selectedUser && <EditMobileUser user={selectedUser} setShowEditModal={setShowEditModal} fetchUsers = {fetchUsers}/>}
//                 </Modal>
//                 <table className='events-table'>
//                     <thead>
//                         <tr>
//                             <th>User Name</th>
//                             <th>First Name</th>
//                             <th>Last Name</th>
//                             <th>Mobile Number</th>
//                             <th>Gothram</th>
//                             <th>Address</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {users.map(user => (
//                             <tr key={user._id}>
//                                 <td>{user.userName}</td>
//                                 <td>{user.firstName || "-"}</td>
//                                 <td>{user.lastName || "-"}</td>
//                                 <td>{user.mobileNumber}</td>
//                                 <td>{user.gothram}</td>
//                                 <td>{user.address || "-"}</td>
//                                 <td className='actionBtnColumn'>
//                                     <button className='web-actionButton' onClick={() => handleEditUser(user)}><DriveFileRenameOutlineIcon/></button>
//                                     <button className='web-actionButton' onClick={() => handleDeleteUser(user._id)}><DeleteIcon/></button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default MobileUsers;



import React, { useEffect, useState } from 'react';
import { getAll, deleteUser } from '../api_calls/mobileUsersApi';
import EditMobileUser from '../components/mobileUsers/editMobileUserForm';
import { Modal, message, Pagination } from 'antd';
import RefreshIcon from '@mui/icons-material/Refresh';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const MobileUsers = () => {
    const [users, setUsers] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        fetchUsers();
    }, [currentPage]); // Update users whenever currentPage changes

    const fetchUsers = async () => {
        const response = await getAll();
        setUsers(response.data.reverse());
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setShowEditModal(true);
    };

    const handleDeleteUser = async (userId) => {
        const response = await deleteUser(userId);
        message.success(response.message);
        await fetchUsers();
    };

    const handleRefresh = async () => {
        await fetchUsers();
        message.success("Users refreshed successfully");
    };

    const handleModalClose = () => {
        setShowEditModal(false);
    };

    // Calculate the current page's users
    const indexOfLastUser = currentPage * pageSize;
    const indexOfFirstUser = indexOfLastUser - pageSize;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    return (
        <div className='mobileUsers'>
            <div>
                <div className='web-header'>
                    <h1>Mobile Users</h1>
                    <button onClick={handleRefresh} className='AddUser'>
                        <RefreshIcon /> <p>Refresh</p>
                    </button>
                </div>
                <hr />
                <Modal
                    title="Edit mobile user"
                    visible={showEditModal}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    {selectedUser && <EditMobileUser user={selectedUser} setShowEditModal={setShowEditModal} fetchUsers={fetchUsers} />}
                </Modal>
                <table className='events-table'>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile Number</th>
                            <th>Gothram</th>
                            <th>Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map(user => (
                            <tr key={user._id}>
                                <td>{user.userName}</td>
                                <td>{user.firstName || "-"}</td>
                                <td>{user.lastName || "-"}</td>
                                <td>{user.mobileNumber}</td>
                                <td>{user.gothram}</td>
                                <td>{user.address || "-"}</td>
                                <td className='actionBtnColumn'>
                                    <button className='web-actionButton' onClick={() => handleEditUser(user)}><DriveFileRenameOutlineIcon /></button>
                                    <button className='web-actionButton' onClick={() => handleDeleteUser(user._id)}><DeleteIcon /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination
                    className='pagination'
                    current={currentPage}
                    total={users.length}
                    pageSize={pageSize}
                    onChange={(page) => setCurrentPage(page)}
                    showQuickJumper={true} 
                />
            </div>
        </div>
    );
};

export default MobileUsers;
