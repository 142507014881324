import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import MainPage from './pages/mainPage';
import HomePage from './staticpages/homepage'
import AboutUs from './staticpages/aboutus'
import People from './staticpages/people'
import Resources from './staticpages/resources'
import EventsPage from './staticpages/eventspage'
import GalleryPage from './staticpages/gallerypage'
import Policies from './staticpages/policies'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import './App.css'
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import NewPassword from './pages/forgotPassword/newPassword';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/people' element={<People />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/srbsevents' element={<EventsPage />} />
          <Route path='/srbsgallery' element={<GalleryPage />} />
          <Route path='/policies' element={<Policies />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path="/newPassword/:userName" element={<NewPassword />} />
          <Route path='*' element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
