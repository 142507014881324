import React, { useEffect, useState } from 'react'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { deleteById, getAll } from '../api_calls/peopleApi';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal, message } from 'antd';
import AddPeopleForm from '../components/people/addPeopleForm'
import EditPeopleForm from '../components/people/editPeopleForm'

const People = () => {
    const [aboutData, setAboutData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await getAll();
        const filteredData = response.data.filter(item => item.category && item.category.includes("people"));
        setAboutData(filteredData);
    }

    const handleModalClose = () => {
        setShowModal(false);
        setShowEditModal(false);
    };

    const handleAddData = () => {
        setShowModal(true);
    }

    const handleDelete = async (id) => {
        var response = await deleteById(id);
        message.success(response.message);
        await fetchData();
    }

    const handleEdit = (data) => {
        setSelectedData(data);
        setShowEditModal(true);
    }

    return (
        <div className='app-about'>
            <div>
                <div className="web-header">
                    <h1>People</h1>
                    <button onClick={handleAddData} className='AddUser'><ControlPointIcon/> <p>Add</p></button>
                </div>
                <hr />
                <Modal
                    title="Add people"
                    visible={showModal}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <AddPeopleForm setShowModal={setShowModal} fetchData = {fetchData}/>
                </Modal>
                <Modal
                    title='Edit people data'
                    visible={showEditModal}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <EditPeopleForm data={selectedData} setShowEditModal={setShowEditModal} fetchData={fetchData}/>
                </Modal>
                <table className="webUser-table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Title</th>
                            <th>Subtitle</th>
                            <th>Description</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {aboutData.map(data => (
                            <tr key={data.id}>
                                <td>{data.userId.userName}</td>
                                <td>{data.title}</td>
                                <td>{data.subTitle}</td>
                                <td>{data.description}</td>
                                <td><img className='about_img' src={"data:image/png;base64,"+data.image} alt=''/></td>
                                <td className='actionBtnColumn'>
                                    <button className="web-actionButton" onClick={() => handleEdit(data)}><DriveFileRenameOutlineIcon/></button>
                                    <button className="web-actionButton" onClick={() => handleDelete(data.id)}><DeleteIcon/></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default People