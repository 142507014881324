import axios from "axios";
import {api} from "../constatns";

const getAll = async () => {
    try {
        const response = await axios.get(api+"about/");
        return response.data;
    } catch (error) {
        console.log(`Error in fetching about data: ${error}`);
    }
};

const add = async (payload) => {
    try {
        const response = await axios.post(api+'about/add', payload);
        return response.data
    } catch (error) {
        console.log(`Error in adding about data: ${error}`);
    }
};

const update = async (id, payload) => {
    try {
        const response = await axios.patch(api+'about/update/'+id, payload);
        return response.data;
    } catch (error) {
        console.log(`Error while updating ${error}`);
    }
}

const deleteById = async(id) => {
    try {
        const response = await axios.delete(api+'about/delete/'+id);
        return response.data;
    } catch (error) {
        console.log(`Error while deleting the entry ${error}`);
    }
}


export {getAll, add, update, deleteById}