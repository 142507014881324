import React, { useEffect, useState } from 'react';
import { getAll } from '../api_calls/transactionsAi';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { message } from 'antd';

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        fetchTransaction();
    }, []);

    const fetchTransaction = async () => {
        var response = await getAll();
        response.data.length!==0?
        setTransactions(response.data.reverse()):setTransactions([]);
    };

    const handleRefresh = async () => {
        await fetchTransaction();
        message.success("Transactions refreshed!")
    };

    const formateDate = (dateString) => {
        let parts = dateString.split("-");
        let year = parts[0];
        let month = parts[1];
        let date = parts[2];
        return `${date}-${month}-${year}`;
    }

    const formateTime = (timeString) => {
        let parts = timeString.split(':')
        let hrs = parts[0];
        let ampm = hrs>=12? 'pm' : 'am';
        hrs = hrs>=12 ? hrs%12 : hrs
        hrs = hrs<10? '0'+hrs : hrs.toString();
        let min = parts[1];
        return `${hrs}:${min} ${ampm}`;
    }

    const formatDateTiime = (timeString) => {
        let parts = timeString.split('T')
        let date = parts[0];
        let time = parts[1];
        let finalDate = formateDate(date);
        let finalTime = formateTime(time);
        return `${finalDate} ${finalTime}`;
    }          

    return (
        <div className='webUsers'>
            <div>
                <div className='web-header'>
                    <h1>Transactions</h1>
                    <button onClick={handleRefresh} className='AddUser'><RefreshIcon/> <p>Refresh</p></button>
                </div>
                <hr />
                <table className='webUser-table'>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Donation Type</th>
                            <th>Amount</th>
                            <th>Date / Time</th>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Reciept ID</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>{transactions.length===0 && <tr><td>No Data to Show</td></tr>}
                        {transactions.length!==0 &&transactions.map(transaction => {
                            return (
                                <tr key={transaction._id} style={{alignSelf:"center", fontSize: "0.9rem"}}>
                                    <td>{transaction.userId.userName}</td>
                                    <td>{transaction.donationName}</td>
                                    <td>{transaction.amount}</td>
                                    <td>{formatDateTiime(transaction.createdAt)}</td>
                                    <td>{transaction.payment_id || "-"}</td>
                                    <td>{transaction.order_id}</td>
                                    <td>{transaction.reciept_id}</td>
                                    <td>{transaction.payment_status === 1? <CheckCircleIcon style={{color: "green"}}/>:<CancelIcon style={{color: "red"}}/>}</td>
                                </tr>
                            )
                            })}
                    </tbody>
                </table>
            </div> 
        </div>
    );
};

export default Transactions;
