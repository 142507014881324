import React from 'react';

const ViewInvitation = ({ invitation }) => {
    return (
        <div style={{ marginLeft: "10rem" , height: "100%"}}>
            <img src={`data:image/png;base64,${invitation}`} alt="Selected" style={{ width: '55%', height: '60%'}} />
        </div>
    );
};

export default ViewInvitation;
