import React, { useState } from 'react';
import { add } from '../../api_calls/galleryApi';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const AddImage = ({ setShowModal, fetchImages }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
    };
    

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (!selectedImage) return;
        const formData = new FormData();
        formData.append('image', selectedImage);
        const localData = JSON.parse(sessionStorage.getItem('userData'));
        await add(formData, localData._id);
        setShowModal(false);
        await fetchImages();
    };
    
    return (
        <div className="modelForm">
            <form onSubmit={handleFormSubmit}>
                <div className="formInputs" style={{flexDirection:"column"}}>
                    <label htmlFor="imageInput" className="custom-file-upload">
                        <FileUploadOutlinedIcon/>
                        Choose image to upload
                        <input type="file" id="imageInput" accept="image/*" className='imageSelector' onChange={handleImageChange} />
                    </label>
                        {selectedImage && (
                            <img src={URL.createObjectURL(selectedImage)} alt="Preview" style={{ maxWidth: "100%", maxHeight: "200px", display:"block" }} />
                        )}
                </div>
                <div className="submit">
                    <button type='submit'>Add Image</button>
                </div>
            </form>
        </div>
    );
    
};

export default AddImage;
