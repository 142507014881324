import React, { useEffect, useState } from 'react';
import { getAll } from '../../api_calls/eventTypeApi';
import { DatePicker, TimePicker, message } from 'antd';
import { add } from '../../api_calls/eventApi';
import moment from 'moment';

const AddEventForm = (props) => {
    const [formData, setFormData] = useState({
        eventType: '',
        startDate: null,
        endDate: null,
        time: null,
        venue: '',
        userId: ''
    });

    const [eventTypes, setEventTypes] = useState([]);

    useEffect(() => {
        fetchEventTypes();
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (userData && userData._id) {
            setFormData(prevState => ({
                ...prevState,
                userId: userData._id
            }));
        };
    }, []);

    const fetchEventTypes = async () => {
        var response = await getAll();
        setEventTypes(response.data);
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleStartDateChange = (date) => {
        setFormData({
            ...formData,
            startDate: date
        });
    };

    const handleEndDateChange = (date) => {
        setFormData({
            ...formData,
            endDate: date
        });
    };

    const handleTimeChange = (time) => {
        setFormData({
            ...formData,
            time: time
        });
    };

    const formatDate = (date) => {
        const day = date["$D"];
        const month = date["$M"] + 1; 
        const year = date["$y"];
        const format = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
        return format;
    }

    const formatTime = (time) => {
        const hour = time["$H"];
        const minute = time["$m"];
        const ampm = hour >= 12 ? 'pm' : 'am';
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const format = `${displayHour < 10 ? '0' : ''}${displayHour}:${minute < 10 ? '0' : ''}${minute} ${ampm}`;
        return format;
    }

    const disabledStartDate = (current) => {
        return current && current < moment().startOf('day');
    };

    const disabledEndDate = (current) => {
        return current && current < moment(formData.startDate).startOf('day');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.startDate = formatDate(formData.startDate);
            formData.endDate = formatDate(formData.endDate);
            formData.time = formatTime(formData.time);
            var response = await add(formData);
            message.success(response.message);
            props.setShowModal(false);
            await props.fetchEvents();
        } catch (error) {
            console.error(`Error during adding Event: ${error}`);
            message.error('Error during adding Event. Please try again later.');
        }
    };

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Event Type:</label>
                        <label style={{marginTop:"0.5rem"}}>Start Date:</label>
                        <label style={{marginTop:"1.2rem"}}>End Date:</label>
                        <label style={{marginTop:"0.5rem"}}>Time:</label>
                        <label style={{marginTop:"0.5rem"}}>Venue:</label>
                    </div>
                    <div className="inputs">
                        <select name="eventType" style={{fontSize: "0.9rem", padding: "0.3rem 0.5rem"}} value={formData.eventType} onChange={handleChange}>
                            <option value="">Select Event Type</option>
                            {eventTypes.map(eventType => (
                                <option key={eventType._id.toString()} value={eventType._id.toString()}>{eventType.title}</option>
                            ))}
                        </select>
                        <DatePicker style={{border: "#4b4b4b solid 1px", borderRadius: "5px", marginBottom: "0.5rem"}}
                            value={formData.startDate}
                            onChange={handleStartDateChange}
                            disabledDate={disabledStartDate}
                            format="YYYY-MM-DD"
                        />
                        <DatePicker style={{border: "#4b4b4b solid 1px", borderRadius: "5px"}}
                            value={formData.endDate}
                            onChange={handleEndDateChange}
                            disabledDate={disabledEndDate}
                            format="YYYY-MM-DD"
                        />
                        <TimePicker style={{border: "#4b4b4b solid 1px", borderRadius: "5px"}}
                            value={formData.time}
                            onChange={handleTimeChange}
                        />
                        <textarea type="text" name="venue" value={formData.venue} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Add Event</button>
                </div>
            </form>
        </div>
    )
}

export default AddEventForm;
