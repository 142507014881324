import React, { useEffect, useState } from "react";
import { update } from "../../api_calls/donationApi";

const EditDonationTypeForm = ({
    donationType,
    setShowEditModal,
    fetchDonationTypes,
    }) => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        amount: "",
    });

    useEffect(() => {
        if (donationType) {
            setFormData({
                title: donationType.title || "",
                description: donationType.description || "",
                amount: donationType.amount || "",
            });
        }
    }, [donationType]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await update(donationType._id, formData);
            setShowEditModal(false);
            await fetchDonationTypes();
        } catch (error) {
            console.log("Error updating donation type: ", error);
        }
    };

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Amount:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                        />
                        <textarea
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="submit">
                    <button type="submit">Update Event Type</button>
                </div>
            </form>
        </div>
    );
};

export default EditDonationTypeForm;
