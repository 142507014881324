/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import '../homestyle.css'
import logo from '../assets/srbslogo2.png'

const Footer = () => {

    return (

        <section id="footer" className='footer'>
            <div className='container'>
                <div className='col-md-3 p-3'>
                    <img className='footer_logo' src={logo} alt='' />
                </div>
                <div className='col-md-3 p-4'>
                    <h4> Policies</h4>
                    <ul className='quick_links'>
                        <li className='footer_item'><a className="nav_link" href='/policies'>Privacy Policy</a></li>
                        <li className='footer_item'><a className="nav_link" href='/policies'>Refund Policy</a></li>
                        <li className='footer_item'><a className="nav_link" href='/policies'>Terms and Conditions</a></li>
                    </ul>
                </div>
                <div className='col-md-3 p-4'>

                    <h4> Contact Details</h4>
                    <ul className='quick_links'>
                        <li className='footer_item'><a className="nav_link" href='/policies'><i className='fa fa-map-marker-alt'></i> :Temple Address</a></li>
                        <li className='footer_item'><a className="nav_link" href='/policies'><i className='fa fa-phone'></i> : 040-23395241, +91-9948659130</a></li>
                    </ul>

                </div>
                <div className='col-md-3 p-4'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7614.853677920043!2d78.45874285505184!3d17.391291799862408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb977ac8390f95%3A0xaaa2f3b73987bf32!2sShree%20Hare%20Ram%20Hanuman%20Mandir!5e0!3m2!1sen!2sin!4v1710766783262!5m2!1sen!2sin" height="240px" style={{ width: '216px', border: 0 }} loading="lazy"></iframe>
                </div>
                <div className='copyright-text'>
                    <p>©2024 Sree Rama Bhakta Samajam(1937)® . All rights reserved</p></div>
            </div>
        </section>


    );
};

export default Footer;