import React, { useEffect, useState } from 'react'
import { getAll } from '../api_calls/donationApi';
import { Modal } from 'antd'; // Import antd Modal and Button
import AddDonationTypeForm from '../components/donationTypes/addDonationTypeForm';
import EditDonationTypeForm from '../components/donationTypes/editDonationTypeForm';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const DonationType = () => {
  const [donationTypes, setDonationTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDonationType, setSelectedDonationType] = useState(null);

  useEffect(() => {
    fetchDonationTypes();
  }, []);

  const fetchDonationTypes = async () => {
    var response = await getAll();
    setDonationTypes(response.data);
  };

  const handleAddDonationType = () => {
    setShowModal(true);
  };

  const handleEditDonationType = async (donationType) => {
    setSelectedDonationType(donationType);
    setShowEditModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
    setShowEditModal(false);
  };

  return (
    <div className='donationTypes'>
      <div>
        <div className='web-header'>
          <h1>Donation Types</h1>
          <button onClick={handleAddDonationType} className='AddUser'><ControlPointIcon/> <p>Add Type</p></button>
        </div>
        <hr />
        <Modal
          title="Add Donation Type"
          visible={showModal}
          onCancel={handleModalClose}
          footer={null}
        >
          <AddDonationTypeForm setShowModal={setShowModal} fetchDonationTypes={fetchDonationTypes}/>
        </Modal>
        <Modal
          title="Edit Donation Type"
          visible={showEditModal}
          onCancel={handleModalClose}
          footer={null}
        >
          <EditDonationTypeForm donationType={selectedDonationType} setShowEditModal={setShowEditModal} fetchDonationTypes={fetchDonationTypes} />
        </Modal>
        <table className='webUser-table'>
          <thead>
              <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
              {donationTypes.map(donationType => (
                  <tr key={donationType._id}>
                      <td>{donationType.title}</td>
                      <td>{donationType.description}</td>
                      <td>{donationType.amount || '-'}</td>
                      <td className='actionBtnColumn'>
                        <button className='web-actionButton' style={{marginLeft:"8rem"}} onClick={() => handleEditDonationType(donationType)}><DriveFileRenameOutlineIcon/></button>
                      </td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DonationType;
