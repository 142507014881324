import React, { useState } from 'react';
import { addUser } from '../../api_calls/webUsersApi';
import { message } from 'antd';

const AddWebUserForm = (props) => {
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
        mobileNumber: '',
        firstName: '',
        lastName: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var response = await addUser(formData);
            if (response && response.success) {
                message.success(response.message);
                props.setShowModal(false);
                await props.fetchUsers();
            } else {
                message.error(response ? response.message : 'Unknown error');
            }
        } catch (error) {
            console.error(`Error during adding web user: ${error}`);
            message.error('Error during adding web user. Please try again later.');
        }
    };

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>User Name:</label>
                        <label>Password:</label>
                        <label>Mobile number:</label>
                        <label>First name:</label>
                        <label>Last name:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name='userName' value={formData.userName} onChange={handleChange} />
                        <input type="password" name='password' value={formData.password} onChange={handleChange} />
                        <input type="tel" name='mobileNumber' value={formData.mobileNumber} onChange={handleChange} pattern='[0-9]*'/>
                        <input type="text" name='firstName' value={formData.firstName} onChange={handleChange} />
                        <input type="text" name='lastName' value={formData.lastName} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Add user</button>
                </div>
            </form>
        </div>
    );
};

export default AddWebUserForm;
