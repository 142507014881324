import { message } from 'antd';
import React, { useState } from 'react'
import { add } from '../../api_calls/aboutApi';

const AppPeopleForm = (props) => {
    var formData = new FormData();
    const[data,setData]=useState({
        title:'',
        subTitle:'',
        description:'',
        image:null,
        category:'people'
    })
    // const [preview,setPreview]= useState();

    const handleChange = (e) => {
        
        if (e.target.name === 'image') {
            setData({...data,image:e.target.files[0]});
            
        } else {
            setData({...data,[e.target.name]:e.target.value});
            
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const localData = JSON.parse(sessionStorage.getItem('userData'));
            formData.append('userId',localData._id);
            formData.append('title',data.title)
            formData.append('subTitle',data.subTitle)
            formData.append('description',data.description)
            formData.append('category',data.category)
            formData.append('image',data.image)
            console.log(formData);
            var response = await add(formData);
            if(response && response.success) {
                message.success(response.message);
                props.setShowModal(false);
                await props.fetchData();
            } else {
                message.error(response ? response.message: 'Unknown error');
            }
        } catch (error) {
            console.error(`Error during adding about data: ${error}`);
        }
    }

    // Function to render image preview
    const renderImagePreview = () => {
        if (data.image) {
            return <img src={URL.createObjectURL(data.image)} alt="Preview" style={{width: "100%"}} />;
        } else {
            return null;
        }
    };

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Sub title:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name='title' value={formData.title} onChange={handleChange} />
                        <input type="text" name='subTitle' value={formData.subTitle} onChange={handleChange}/>
                        <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
                    </div>
                </div>
                <input type="file" name="image" onChange={handleChange}  style={{marginBottom: "2rem"}}/>
                {renderImagePreview()}
                <div className="submit">
                    <button type='submit'>Add Data</button>
                </div>
            </form>
        </div>
    )
}

export default AppPeopleForm;
