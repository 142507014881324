import React, { useState } from 'react'
import { uploadInvitation } from '../../api_calls/eventApi';
import { Modal } from 'antd';

const InvitationUploader = ({visible, onClose, id, fetchEvents}) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUpload = async (event) => {
        event.preventDefault();
        if(file) {
            setLoading(true);
            const formData = new FormData();
            formData.append('image', file);
            await uploadInvitation(id, formData, onClose());
            await fetchEvents();
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
            setFile(selectedFile);
        } else {
            alert('Please select a JPEG or PNG image file.');
        }
    };

    return (
        <Modal
            title="Upload invitation"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div>
                <input type="file" accept='image/*' onChange={handleFileChange} />
                <button onClick={handleUpload} disabled={!file || loading}>
                    Upload Invitation
                </button>
                {file && (
                    <img src={URL.createObjectURL(file)} alt="Preview" style={{ maxWidth: "100%", maxHeight: "200px", display:"block" }} />
                )}
            </div>
        </Modal>
    )
}

export default InvitationUploader