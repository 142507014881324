import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { update } from '../../api_calls/peopleApi';

const EditPeopleForm = ({data, setShowEditModal, fetchData}) => {
    const [formData, setFormData] = useState({
        title: '',
        subTitle: '',
        description: '',
    });

    useEffect(() => {
        if(data){
            setFormData({
                title: data.title,
                subTitle: data.subTitle,
                description: data.description,
            })
        }
    }, [data]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await update(data.id, formData);
            if(response.success){
                message.success(response.message);
                setShowEditModal(false);
                await fetchData();
            } else{
                message.error(response.message);
            }
        } catch (error) {
            message.error('Error updating about data: ', error)
        }
    }

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Sub Title:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name='title' value={formData.title} onChange={handleChange} />
                        <input type="text" name='subTitle' value={formData.subTitle} onChange={handleChange} />
                        <textarea type="number" name='description' value={formData.description} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Update data</button>
                </div>
            </form>
        </div>
    )
}

export default EditPeopleForm