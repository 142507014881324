import React, { useState, useEffect } from 'react';
import { editUser } from '../../api_calls/webUsersApi';
import { message } from 'antd';

const EditWebUser = ({ user, setShowEditModal, fetchUsers }) => {
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
        mobileNumber: '',
        firstName: '',
        lastName: ''
    });

    // Update formData whenever user prop changes
    useEffect(() => {
        if (user) {
            setFormData({
                userName: user.userName,
                password: user.password,
                mobileNumber: user.mobileNumber,
                firstName: user.firstName,
                lastName: user.lastName
            });
        }
    }, [user]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await editUser(user._id, formData);
            if (response.success) {
                message.success(response.message);
                setShowEditModal(false);
                await fetchUsers();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>User Name:</label>
                        <label>Password:</label>
                        <label>Mobile number:</label>
                        <label>First name:</label>
                        <label>Last name:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name='userName' value={formData.userName} onChange={handleChange} />
                        <input type="password" name='password' value={formData.password} onChange={handleChange} />
                        <input type="number" name='mobileNumber' value={formData.mobileNumber} onChange={handleChange} />
                        <input type="text" name='firstName' value={formData.firstName} onChange={handleChange} />
                        <input type="text" name='lastName' value={formData.lastName} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Update user</button>
                </div>
            </form>
        </div>
    );
}

export default EditWebUser;
