import { Modal } from "antd";
import React, { useState } from "react";
import { uploadProgramPaper } from "../../api_calls/eventApi";


const PdfUploader = ({ visible, onClose, id, fetchEvents }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUpload = async (event) => {
        event.preventDefault();
        if (file) {
            setLoading(true);
            const formData = new FormData();
            formData.append('pdf', file);
            await uploadProgramPaper(id, formData);
            setFile(null);
            onClose();
            await fetchEvents();
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile);
        } else {
            alert('Please select a PDF file.');
        }
    };

    return (
        <Modal
            title="Upload PDF"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div>
                <input type="file" accept="application/pdf" onChange={handleFileChange} />
                <button onClick={handleUpload} disabled={!file || loading}>
                    Upload PDF
                </button>
                {file && (
                    <div>
                        <p>Selected file: {file.name}</p>
                        <embed src={URL.createObjectURL(file)} type="application/pdf" width="100%" height="600px" />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PdfUploader;