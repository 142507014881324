import React, { useState } from 'react'
import {add} from '../../api_calls/eventTypeApi'

const AddEventTypeForm = (props) => {
    const [formData, setFormData] = useState({
        title: '',
        duration: '',
        description: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var response = await add(formData);
        console.log(response);
        props.setShowModal(false);
        await props.fetchEventTypes();
    };
    
    return (
        <div className="modelForm">
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>Title:</label>
                        <label>Duration:</label>
                        <label>Description:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name="title" value={formData.title} onChange={handleChange} />
                        <input type="text" name="duration" value={formData.duration} onChange={handleChange} />
                        <input type="text" name="description" value={formData.description} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type='submit'>Add event type</button>
                </div>
            </form>
        </div>
    )
}

export default AddEventTypeForm