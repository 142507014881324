import React, { useState } from 'react';
import {  editUser } from '../../api_calls/mobileUsersApi';

const EditMobileUser = ({ user, setShowEditModal, fetchUsers }) => {
    const [formData, setFormData] = useState({
        userName: user.userName,
        password: user.password,
        mobileNumber: user.mobileNumber,
        firstName: user.firstName,
        lastName: user.lastName,
        gothram: user.gothram,
        address: user.address,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await editUser(user._id, formData);
            setShowEditModal(false); 
            await fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };
    
    return (
        <div className='modelForm'>
            <form onSubmit={handleSubmit}>
                <div className="formInputs">
                    <div className="labels">
                        <label>User Name:</label>
                        <label>Mobile Number:</label>
                        <label>First Name:</label>
                        <label>Last Name:</label>
                        <label>Gothram:</label>
                        <label>Address:</label>
                    </div>
                    <div className="inputs">
                        <input type="text" name="userName" value={formData.userName} onChange={handleChange} />
                        <input type="number" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                        <input type="text" name="gothram" value={formData.gothram} onChange={handleChange} />
                        <textarea type="text" name="address" value={formData.address} onChange={handleChange} />
                    </div>
                </div>
                <div className="submit">
                    <button type="submit">Update User</button>
                </div>
            </form>
        </div>
    );
}

export default EditMobileUser;
