import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginform } from '../api_calls/webUsersApi';
// import { message } from 'antd';
import TopNavBar from '../components/TopNavbar'
import Footer from '../components/Footer'
// import banner from '../assets/Banners.jpg'
import '../homestyle.css'
import { getAll } from '../api_calls/peopleApi';

const People = () => {
    const [peopleData, setPeopleData] = useState([]);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        const response = await getAll();
        const filteredData = response.data.filter(item => item.category && item.category.includes("people"));
        setPeopleData(filteredData);
    }

    return (
        <div className="main">
            <TopNavBar />
            <section className='home-section'>
                <div className='home'>
                    <div className='container title_section'>
                        <h4>People</h4>
                    </div>
                    <div className='container page_content'>
                        <div className='content'>
                        {peopleData.length > 0 ? <table className="webUser-table">
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {peopleData.map(data => (
                                    <tr key={data._id}>
                                        <td>{data.userId.userName}</td>
                                        <td>{data.title}</td>
                                        <td>{data.subTitle}</td>
                                        <td>{data.description}</td>
                                        <td><img className='about_img' src={"data:image/png;base64,"+data.image} alt=''/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>: <h2 style={{textAlign: "center"}}>No data to show</h2>}
                        </div>
                    </div>
                </div>
            </section>
            <div className='home'>

            </div>
            <Footer />
        </div>
    );
};

export default People;