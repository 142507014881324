import React, { useEffect, useState } from 'react';
import AddImage from '../components/gallery/addImage';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { deleteImage, get } from '../api_calls/galleryApi';
import { Modal, message } from 'antd';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        var response = await get();
        if(response.images)
        setImages(response.images);
    }

    const handleAddImage = () => {
        setShowModal(true);
    };

    const handleDeleteImage = async (index) => {
        var response = await deleteImage(index);
        await fetchImages();
        message.success(response.message);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const formateDate = (dateString) => {
        let datePartDivision = dateString.split('T');
        let parts = datePartDivision[0].split("-");
        let year = parts[0];
        let month = parts[1];
        let date = parts[2];
        return `${date}-${month}-${year}`;
    }

    // const formateTime = (timeString) => {
    //     let parts = timeString.split(':')
    //     let hrs = parts[0];
    //     let ampm = hrs>=12? 'pm' : 'am';
    //     hrs = hrs>=12 ? hrs%12 : hrs
    //     hrs = hrs<10? '0'+hrs : hrs.toString();
    //     let min = parts[1];
    //     return `${hrs}:${min} ${ampm}`;
    // }

    // const formatDateTiime = (timeString) => {
    //     let parts = timeString.split('T')
    //     let date = parts[0];
    //     let time = parts[1];
    //     let finalDate = formateDate(date);
    //     let finalTime = formateTime(time);
    //     return `${finalDate} ${finalTime}`;
    // }   

    return (
        <div className='gallery'>
            <div className='web-header'>
                <h1>Gallery</h1>
                <button onClick={handleAddImage} className='AddUser'><ControlPointIcon/> <p>Add Image</p></button>
            </div>
            <hr />
            <Modal
                title="Add Image"
                visible={showModal}
                onCancel={handleModalClose}
                footer={null}
            >
                <AddImage setShowModal={setShowModal} handleAddImage={handleAddImage} fetchImages={fetchImages} />
            </Modal>
            <div className="imageContainer">
                {images.map((images) => (
                    <div>
                        <div key={images.id} className="imageWrapper">
                            <div className="imageWithDeleteIcon" style={{ backgroundImage: `url(data:image/png;base64,${images.image})` }}>
                                <button className='deleteButton' onClick={() => handleDeleteImage(images.id)}><DeleteIcon style={{color: "#eee"}}/></button>
                            </div>
                        </div>
                        <div className='imageUserName' style={{fontSize:"0.8rem", display: "flex", justifyContent:"space-between", width: "85%",padding:"0.2rem 0.5rem"}}>
                            <p style={{color: "blue"}}>@{images.userName}</p>
                            <p>{formateDate(images.createdAt)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Gallery;
